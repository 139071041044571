style = $.cookie('style');
font = $.cookie('font');
if (style === "wcag") {
    wcagStyle(document.createEvent('Event'));
}
if (font === "medium") {
    mediumFont(document.createEvent('Event'));
}
if (font === "large") {
    largeFont(document.createEvent('Event'));
}

function normalStyle(event) {
    event.preventDefault();
    $('#main-style').attr('href', '/css/frontend/stylesheet.css');
    $.cookie('style', 'normal', {
        expires : 365,
        path    : '/'
    });
}

function wcagStyle(event) {
    event.preventDefault();
    $('#main-style').attr('href', '/css/frontend/stylesheet-wcag.css');
    $.cookie('style', 'wcag', {
        expires : 365,
        path    : '/'
    });


}

function smallFont(event) {
    event.preventDefault();
    document.documentElement.className = 'html html--font-small';
    $.cookie('font', 'small', {
        expires : 365,
        path    : '/'
    });
}

function mediumFont(event) {
    event.preventDefault();
    document.documentElement.className = 'html html--font-medium';
    $.cookie('font', 'medium', {
        expires : 365,
        path    : '/'
    });
}

function largeFont(event) {
    event.preventDefault();
    document.documentElement.className = 'html html--font-large';
    $.cookie('font', 'large', {
        expires : 365,
        path    : '/'
    });
}

$(document).ready(function () {


    $('.wcag__small').click(function(){
        $(this).addClass('active');
        $('.wcag__medium').removeClass('active');
        $('.wcag__large').removeClass('active');
    })

    $('.wcag__medium').click(function(){
        $(this).addClass('active');
        $('.wcag__small').removeClass('active');
        $('.wcag__large').removeClass('active');
    })

    $('.wcag__large').click(function(){
        $(this).addClass('active');
        $('.wcag__small').removeClass('active');
        $('.wcag__medium').removeClass('active');
    })

    $('.wcag__normal').click(function(){
        $(this).addClass('active');
        $('.wcag__contrast').removeClass('active');
    })

    $('.wcag__contrast').click(function(){
        $(this).addClass('active');
        $('.wcag__normal').removeClass('active');
    })




    if ($.cookie('style') == undefined) {

        $('.wcag__contrast').removeClass('active');
        $('.wcag__normal').addClass('active');
    }

    if ($.cookie('style') == 'wcag') {
        $('.wcag__contrast').addClass('active');
        $('.wcag__normal').removeClass('active');
    }

    if ($.cookie('style') == 'normal') {
        $('.wcag__contrast').removeClass('active');
        $('.wcag__normal').addClass('active');
    }


    if ($.cookie('font') == undefined) {
        $('.wcag__small').addClass('active');
    }


    if ($.cookie('font') == 'small') {
        $('.wcag__large').removeClass('active');
        $('.wcag__medium').removeClass('active');
        $('.wcag__small').addClass('active');
    }

    if ($.cookie('font') == 'medium') {
        $('.wcag__large').removeClass('active');
        $('.wcag__medium').addClass('active');
        $('.wcag__small').removeClass('active');
    }

    if ($.cookie('font') == 'large') {
        $('.wcag__large').addClass('active');
        $('.wcag__medium').removeClass('active');
        $('.wcag__small').removeClass('active');
    }

});